import styles from "./Race.module.css";
import {useState} from "react";

function GameStats(props: any) {
    const [gameStatsProperties, setGameStatsProperties] = useState({
        leader: "",
        leaderCar: "",
        leaderStep: 0,
        bank: 0
    })

    return <div className={styles.statsPanel}>

        <div className={styles.statsPanelName}>
            game stats
        </div>

        <div className={styles.statsInfo}>

            <div className={styles.leaderInfo}>
                Leader: name & car name
                {/*{gameStatsProperties.leader} : {gameStatsProperties.leaderCar}*/}
            </div>

            <div>
                Race step: {gameStatsProperties.leaderStep}
            </div>

            <div>
                Bank: {gameStatsProperties.bank}
            </div>

            <div>
                your step: {props.userStep}
            </div>

        </div>

    </div>
}

export default GameStats
import styles from "./Main.module.css"
import homeImg from "./lobby.png"
import {useLocation, useNavigate} from "react-router-dom";
function Main(){
    const navigate = useNavigate()
    const location = useLocation()

    function toNextPage(){
        try {
            if(location.state.name){
                return navigate("/lobby", {
                    state: {name: location.state.name}
                })
            } else {
                return navigate("/registration")
            }
        }
        catch{
            return navigate("/registration")
        }
    }

    return <div className={styles.home}>

        <div className={styles.main}>

            <img src={homeImg} className={styles.imgHome} alt="Lobby" />

            <div className={styles.leftContent}>
                <div className={styles.gameName}>AeThla</div>
                <div className={styles.line}></div>
                <div className={styles.first}>
                    Assemble your car
                </div>
                <div className={styles.second}>Play & earn</div>
            </div>

            <div className={styles.rightContent}>
                <button className={styles.startGame}
                        onClick={() => toNextPage()}
                >
                    play game
                </button>
            </div>

        </div>

    </div>
}

export default Main
import {useState} from "react";
// import { useNavigate} from "react-router-dom";
import styles from "./Garage.module.css";
// import lobby from "./lobby.png";
import garage from "./garage.png";
import car from "./garageCar.png"
import Header from "./Header";
import CarProperties from "./CarProperties";
import CarButtons from "./CarButtons";
import {useLocation} from "react-router-dom";

function DemoGarage() {
    const location = useLocation()
    // const navigate = useNavigate()

    const [carProperties, setCarProperties] = useState({
        carType: "",
        carRarity: "",
        carHealth: 0,
        carFuel: 0
    })

    const saveChangeCarProperties = (carPropertiesSave: any) => {
        setCarProperties(carPropertiesSave)
    }

    return (
        <div className={styles.garage}>

            <Header btnHeaderText="lobby" btnHeaderLink="/lobby" userName={location.state.name}/>

            <div className={styles.main}>
                <img src={garage} className={styles.imgGarage} alt="Garage"/>

                <div className={styles.pageName}>
                    garage
                </div>

                <div className={styles.content}>
                    <div className={styles.car}>
                        <img src={car} className={styles.imgCar} alt="Car"/>
                    </div>

                    <div className={styles.mechanics}>

                        <div className={styles.carProperties}>
                            <CarProperties carProperties={carProperties}/>
                        </div>

                        <div className={styles.carBtns}>
                            <CarButtons showAssambleButton={true}
                                        save={saveChangeCarProperties}
                                        carProperties={carProperties}
                            />
                        </div>

                    </div>
                </div>

            </div>

            <div className={styles.futter}>
                &copy; AeThla
            </div>

        </div>
    )
}

export default DemoGarage
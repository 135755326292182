import styles from "./Header.module.css";
import {useNavigate} from "react-router-dom";

function Header(props: any) {
    const navigate = useNavigate()
    const link = props.btnHeaderLink

    return <header>
        <button className={styles.btnHeader}
                onClick={() => {
                    navigate(link,
                        {
                            state: {
                                name: props.userName
                            }
                        }
                    )
                }}
        >{props.btnHeaderText}
        </button>

        <button className={styles.headerInfo}>
            <div className={styles.waxLogo}>
                wax
            </div>
            <div className={styles.waxName}>
                {props.userName}
            </div>
            <div className={styles.waxBalance}>
                327.32
            </div>
        </button>
    </header>
}

export default Header
import styles from "./Race.module.css";
import garageCar from "./garageCar.png";
import CarProperties from "./CarProperties";
import {useState} from "react";
import CarButtons from "./CarButtons";

function CarStats() {
    const [carProperties, setCarProperties] = useState({
        carType: "",
        carRarity: "",
        carHealth: 0,
        carFuel: 0
    })

    const saveChanged = (carPropertiesSave: any) => {
        setCarProperties(carPropertiesSave)
    }

    return <div className={styles.carPanel}>
        <div className={styles.carPanelName}>
            car
        </div>

        <div className={styles.currentCar}>
            <img src={garageCar} className={styles.imgCar} alt="Car"/>
        </div>

        <div className={styles.carProperties}>
            <CarProperties carProperties={carProperties}/>
        </div>

        <div className={styles.carBtns}>
            <CarButtons showAssambleButton={false} save={saveChanged} carProperties={carProperties}/>
        </div>

    </div>
}

export default CarStats
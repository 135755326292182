import {useState} from "react";
import {useNavigate} from "react-router-dom"
import styles from "./Registration.module.css"

function Registration() {
    const [userName, setUserName] = useState('');

    const navigate = useNavigate()

    async function logIn() {
        if (userName.length === 8) {
            // const response = await fetch(
            //     "https://aesla-back.yulian2002.workers.dev",
            //     {method: "POST", body: JSON.stringify(userName)})
            //
            // const result = await response.json()
            // navigate("/lobby", {
            //     state: result
            // })
            navigate("/lobby", {
                state: {"name": userName}
            })
        } else {
            setUserName("");
        }

    }

    const handleChange = (event: any) => {
        const result = event.target.value.replace(/[^a-z]/gi, '');
        setUserName(result);
    };

    return (
        <div className={styles.registration}>
            <div className={styles.main}>
                <input
                    className={styles.inputName}
                    placeholder="text your userName"
                    type="text"
                    name="username"
                    value={userName}
                    onChange={handleChange}
                />
                <button
                    className={styles.btnLogin}
                    onClick={() => logIn()}>
                    Log in
                </button>
            </div>


        </div>
    )
}

export default Registration
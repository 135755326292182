export enum ETurnType {
    Left,
    Forward,
    Right,
}

export enum EOperation {
    update,
    action,
    login,
  }

export enum EChoice {
    left,
    forward,
    right,
}

export interface IReqData {
    op: EOperation,
    lastUpdated?: number,
    choice?: EChoice,
    playerName?: string,
}

export interface IResData {
    status: boolean,
    lastUpdated?: number,
    data?: IRaceData
}

export interface IRaceData {
    race: number[],
    leader?: string,
    nextTurn: EChoice,
}
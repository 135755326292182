import {useNavigate, useLocation} from "react-router-dom";
import race from "./race.png"
import garage from "./garage.png"
import lobby from "./lobby.png"
import styles from "./Lobby.module.css"
import Header from "./Header";

function Lobby() {
    const location = useLocation()

    const navigate = useNavigate()

    return <div className={styles.lobby}>
        <Header btnHeaderText="main" btnHeaderLink="/main" userName={location.state.name}/>

        <div className={styles.main}>
            <img src={lobby} className={styles.imgLobby} alt="Lobby"/>

            <div className={styles.raceContainer}
                 onClick={() => {
                     navigate("/race",
                         {
                             state: {
                                 name: location.state.name
                             }
                         }
                     )
                 }}
            >
                <img src={race} className={styles.imgRace} alt="Race"/>
                <button className={styles.btnRace}>
                    race
                </button>
            </div>

            <div className={styles.garageContainer}
                 onClick={() => {
                     navigate("/garage",
                         {
                             state: {
                                 name: location.state.name
                             }
                         }
                     )
                 }}
            >
                <img src={garage} className={styles.imgGarage} alt="Garage"/>
                <button className={styles.btnGarage}>
                    garage
                </button>
            </div>

        </div>

        <div className={styles.futter}>
            &copy; AeThla
        </div>

    </div>
}

export default Lobby
// import {EChoice, EOperation, IReqData, IResData} from "./types";
// import {useState} from "react";
// import {useLocation, useNavigate} from "react-router-dom";
import raceImg from "./race.png"
import styles from "./Race.module.css";
import Header from "./Header";
import CarStats from "./CarStats";
import GameStats from "./GameStats";
import Road from "./Road";
import {useLocation} from "react-router-dom";
import {useState} from "react";

function Race() {
    const location = useLocation()

    const [userStep, setUserStep] = useState(0)
    const saveNewStep = (step: any) => {
        setUserStep(step)
    }

    return <div className={styles.race}>

        <Header btnHeaderText="exit" btnHeaderLink="/lobby" userName={location.state.name}/>

        <div className={styles.main}>
            <img src={raceImg} className={styles.imgRace} alt="RaceImg"/>

            <CarStats/>

            <Road saveNewStep = {saveNewStep} />

            <GameStats userStep={userStep} />
        </div>

        <div className={styles.futter} style={{height: "10%"}}>
            &copy; AeThla
        </div>

    </div>
}

export default Race

import styles from "./CarProperties.module.css"

function CarProperties(props: any) {
    function toPercent(carData: number) {
        return String(carData) + "%"
    }

    return <div>
        <div className={styles.carType}>type: {props.carProperties.carType}</div>
        <div className={styles.carRarity}>rarity: {props.carProperties.carRarity}</div>

        <div className={styles.carHealthPanel}>

            <div className={styles.carHealth}>
                health:
            </div>

            <div className={styles.carHealthScale}>
                <div className={styles.carHealthIndicator} style={{width: toPercent(props.carProperties.carHealth)}}>
                    {toPercent(props.carProperties.carHealth)}
                </div>

            </div>

        </div>

        <div className={styles.carFuelPanel}>

            <div className={styles.carFuel}>
                fuel:
            </div>

            <div className={styles.carFuelScale}>
                <div className={styles.carFuelIndicator} style={{width: toPercent(props.carProperties.carFuel)}}>
                    {toPercent(props.carProperties.carFuel)}
                </div>
            </div>

        </div>

    </div>

}

export default CarProperties
import Main from "./Main";
function App() {
  return (
      <div className="App">
          <Main/>
      </div>
  );
}

export default App;

import { createBrowserRouter }  from 'react-router-dom';
import App from "./App"
import Registration from "./Registration";
import Lobby from "./Lobby";
import Race from "./DemoRace";
import Garage from "./DemoGarage";
import Main from "./Main";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>
    },
    {
        path: "/registration",
        element: <Registration/>
    },
    {
        path: "/race",
        element: <Race/>
    },
    {
        path: "/garage",
        element: <Garage/>
    },
    {
        path: "/lobby",
        element: <Lobby/>
    },
    {
        path: "/main",
        element: <Main/>
    }
]);

export  default router
import styles from "./Race.module.css";
import {EChoice} from "./types";
import {useState} from "react";
// import {postRequest, updateRequest} from "./request"
function Road(props: any){
    const [correctButton, setCorrectButton] = useState<boolean|undefined>();
    const [direction, setDirection] = useState<EChoice | undefined>()
    const [isDisable, setIsDisable] = useState(false)

    function detain(check: boolean){
        setCorrectButton(check)
        setIsDisable(true)
        setTimeout(() => {
            setCorrectButton(undefined)
            setDirection(undefined)
            setIsDisable(false)
        }, 2000);
    }

    function chooseWay(direction: EChoice) {

        // const chooseStep: IReqData = {op: EOperation.action, choice: direction};
        //
        // postRequest(chooseStep).then(result => {
        //     setDirection(direction)
        //     detain(result.ok)
        // })

        setDirection(direction)
        detain(true)
        // props.saveNewStep(result.newStep)
    }

    return <div className={styles.racePanel}>
        <div className={styles.map}>
            map
        </div>

        <div className={styles.road}>

            <button
                disabled={isDisable}
                style={{backgroundColor: direction === EChoice.left  ? 'green' :
                        correctButton === undefined  ? 'gray' : 'red',

                }}
                onClick={() => chooseWay(EChoice.left)}
            >
                left
            </button>

            <button
                disabled={isDisable}
                style={{backgroundColor: direction === EChoice.forward  ? 'green' :
                        correctButton === undefined  ? 'gray' : 'red'}}
                onClick={() => chooseWay(EChoice.forward)}
            >
                toward
            </button>

            <button
                disabled={isDisable}
                style={{backgroundColor: direction === EChoice.right  ? 'green' :
                        correctButton === undefined  ? 'gray' : 'red'}}
                onClick={() => chooseWay(EChoice.right)}
            >
                right
            </button>
        </div>
    </div>
}

export default Road
import styles from "./CarButtons.module.css"

export default function CarButtons(props: any) {
    function updateCarProperties(option: string) {
        const currentCarProperties = {...props.carProperties}

        switch (option) {
            case "health":
                if (currentCarProperties.carHealth !== 100){
                    // repairRequest
                    currentCarProperties.carHealth = 100
                }
                break
            case "fuel":
                if (currentCarProperties.carFuel !== 100){
                    // refillRequest
                    currentCarProperties.carFuel = 100
                }
                break
        }

        props.save(currentCarProperties)
    }

    function repairClick() {
        updateCarProperties("health")
    }

    function refillClick() {
        updateCarProperties("fuel")
    }

    return <div className={styles.carBtns}>
        <button
            onClick={() => refillClick()}
        >
            refill
        </button>

        {props.showAssambleButton &&
            <button>
                assemble
            </button>
        }

        <button onClick={() => repairClick()}>
            repair
        </button>
    </div>
}
